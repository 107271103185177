body{margin:0; padding:0; text-align:center; font-family: 'Lato', sans-serif; background:#FFF; overflow-x:hidden; overflow-y:auto; font-size:13px;}
.bl{float:left; text-transform:uppercase;color:#2d2d72;width:35px;background:#e28926;padding: 6px 0px 6px 6px;cursor:pointer;line-height:18px;}
.bl1{float:left; text-transform:uppercase;color:#2d2d72;width:80px;background:#e28926;padding: 6px 0px 6px 6px;cursor:pointer;line-height:18px;}
.br{float:left;color:#e28926;width:20px;background:#2d2d72;text-align:center;padding: 6px 0; cursor:pointer;line-height:18px;}
.clear{clear:both;}
.header0{color:#000;max-width:100%;border-top:2px solid #f69527;background: #000;}
.header{width:95%; max-width:1150px; margin:0 auto;}
.header1{color:#000;max-width:100%;border-top:2px solid #f69527;background: #000;height:195px; background:url(../images/topo.jpg);border-bottom:3px solid #FFF;}
.header .left{width:23%; float:left; position:relative; z-index:5;}
.header .left img{width:auto; max-width:100%}
.header .right{width:77%; float:left; position:relative; z-index:10; margin-top: 3%; top:-2px;}
.header .topContent{height:40px; width:100%;position:relative;float:left;z-index:99;text-align:right;font-size: 26px;margin:5px 0 20px -15px;}
.header .topContent a{color:#FFF; border:1px solid #f69527;font-size:12px; padding:5px 10px; text-decoration:none;text-transform:uppercase; cursor:pointer;line-height: 35px;	}
.banner{position:absolute;left:0;width:100%; z-index:1;}
.banner img{width:100%;}
.conteudo0{width:100%; overflow:hidden;}
.banInt{width:100%;}
.conteudo{width:95%; max-width:1150px; margin: 0 auto; position: relative; z-index: 5;}
.blocoTexto{width:25%; -moz-box-sizing:border-box; box-sizing:border-box; padding:0 10px;float:left;margin-top:-25px; position:relative; z-index:4; cursor:pointer;}
.blocoInterno{padding:13px; background:#FFF;
box-shadow: 0px 4px 41px -8px rgba(0,0,0,0.75);text-align: left;}
.blocoInterno:hover{-webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);}
.blocoInterno img{width:100%;}
.texto{padding: 10px;margin-top: -3px;}
.texto .title{font-size: 17px;color: #FFF;margin: 0;}
.sobrenos{text-align:left;margin-top:40px;font-size:13px; color:#444; text-align:justify;}
.sobrenos p{font-size: 24px;letter-spacing: -1px; }
.azul{letter-spacing:0;font-size: 26px;text-transform: uppercase;font-weight: bold;color: #2d2d72; padding-bottom:10px; border-bottom:3px solid;}
.azul-s{letter-spacing:0;font-size: 26px;text-transform: uppercase;font-weight: bold;color: #2d2d72; padding-bottom:10px;}	
.sobreEsquerda{width:68%;-moz-box-sizing:border-box;box-sizing:border-box;float:left;padding-right:50px;}
.sobreDireita{width:32%;-moz-box-sizing:border-box;box-sizing:border-box;float:left;}
.servicos{text-align:left;font-size:13px;color:#666;}
.servicos p{font-size: 24px;letter-spacing: -1px; }
.textoServicos {
    width: 40%;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    margin-top: -75px;
    background: #FFF;
    position: relative;
    z-index: 4;
    box-shadow: 0px 4px 41px -8px rgba(0,0,0,0.75);
    padding: 20px 40px;
	text-align:justify;
	line-height:28px;
	float:left;
}
.openServicos {
    width: 40%;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    margin-top: -445px;
    background: #FFF;
    position: relative;
    z-index: 4;
    box-shadow: 0px 4px 41px -8px rgba(0,0,0,0.75);
    padding: 20px 40px;
	text-align:justify;
	line-height:28px;
	float:left;
}
.texto70{width:70%; padding-bottom:30px;border-bottom:3px solid #2d2d72;}
.move { -moz-transition: all 0.5s ease; transition: all 0.5s ease;}
.servhome{width:60%;-moz-box-sizing: border-box;box-sizing: border-box;padding:15px; float:left; }
.servicoshome{float:left; width:33%;text-align:left; -moz-box-sizing:border-box; box-sizing:border-box;padding: 10px; min-height:170px; border:1px solid #FFF;}
.servicoshome:hover{background:#ff960c; color:#FFF; cursor:pointer;
    box-shadow: 0px 4px 41px -8px rgba(0,0,0,0.75);border: 5px solid #FFF}
.servicoshome img{float:left;width:auto;max-width:30%;}
.servicoshome:hover img{-webkit-filter: invert(100%); filter: invert(100%);}
.servicoshome p{float:left; margin:5px 0;font-size:13px;}
.servicoshome .serv{    font-size: 14px;
    font-weight: bold;
    margin-top: 25px;
    text-align: right;
    width: 60%;}
.downFolder{background:url(../images/folder.jpg) center center no-repeat; min-height:130px;}
.downFolder1{    width: 90%;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    max-width: 1150px;
    margin: 0 auto;
    padding: 0 75px;}
.downFolder1 .texto{font-size: 28px;
    width:65%;
	-moz-box-sizing:border-box;
	     box-sizing:border-box;
    font-weight: bold;
    color: #FFF;float:left;text-align:left;padding-top:3%;}
.downFolder1 .botao{width:35%; -moz-box-sizing:border-box; box-sizing:border-box; text-align:center;float:left;padding-top:3%;}
.downFolder1 a{    font-size: 20px;
    color: #FFF;
    text-decoration: none;
    border: 2px solid;
    padding: 15px 25px;}
.downFolder1 a:hover{border-color:#e28926;}
.rodape0{background:#000;width:100%; }
.rodape{width:100%; max-width:1150px; margin: 0 auto;}
.rodape .logo{width: 25%;
    background: #FFF;
    padding: 10px 15px;
    font-size: 12px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    color: #777;}
.rodape .logo img{width:auto; max-width:100%}
.rodape .menuRodape{width:20%; color:#cccccc;-moz-box-sizing: border-box;box-sizing: border-box; float:left;text-align:left;padding:10px 25px;;}
.rodape a{color:#FFF; text-decoration:none;}
.rodape .menuRodape ul{padding:0;text-transform:uppercase; font-size:10px; list-style:none;}
.rodape .menuRodape li{margin: 10px 0;}
.rodape .contatoRodape{width:20%; color:#cccccc; -moz-box-sizing: border-box; box-sizing: border-box; float:left;text-align:left;line-height:30px;font-size: 12px;font-weight: bold;}
.frotaRodape{width:35%; color:#cccccc; -moz-box-sizing:border-box; box-sizing:border-box; float:left;}
.frotaRodape img{width:100%;margin-top:-50px;}
.news{background: #313131;width: 70%;-moz-box-sizing: border-box;box-sizing: border-box;padding: 6px;color: #696969;border: none;border-radius: 5px;}
.news:focus{color:#DDDDDD;}

/********************************INSTITUCIONAL*******************************/
.missao{width:33%; -moz-box-sizing:border-box; box-sizing:border-box; padding:20px;text-align:left;; float:left;}
.missao .conteudo{
box-shadow: 0px 4px 41px -8px rgba(0,0,0,0.75);text-align: left;}
.missao .texto{ color:#AAA;}
.missao .titulo{padding-left:10px;text-transform: uppercase;
    color: #2c2e30;
    font-weight: bold;
    font-size: 22px;}
.servInst0{width:100%;background:url(../images/instbot.jpg) center center no-repeat; padding:40px 0;}
.servInst{width:90%; max-width:600px;margin:0 auto; background:#FFF; text-align:left; -moz-box-sizing:border-box; box-sizing:border-box; padding:10px 10px 10px 150px;color:#777;}
.missao:hover{margin-top:-50px;}
.separa{height:44px; color:#FFF;}
/********************************Contato*******************************/
.textoContato {
    width: 40%;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    margin-top: -35px;
    background: #FFF;
    position: relative;
    z-index: 4;
    box-shadow: 0px 4px 41px -8px rgba(0,0,0,0.75);
    padding: 40px 40px;
	text-align:justify;
	line-height:28px;
	float:left;
	color:#444;
}
.textoContato .titulo{font-size:28px;}
.mapa{margin-top:25px;
    box-shadow: 0px 4px 41px -8px rgba(0,0,0,0.75);width:500px; height:300px;}
.contato{display:block;width:100%;text-align:left;}
.contato .titulo{text-align:center; font-size:26px; text-transform:uppercase;font-weight:bold;color:#2d2d72;}
.fLeft{width:50%; -moz-box-sizing:border-box; box-sizing:border-box; padding:0 20px; float:left;}
.fRight{width:50%; -moz-box-sizing:border-box; box-sizing:border-box; padding:0 20px;float:left;}
.textarea{width:100%; -moz-box-sizing:border-box; box-sizing:border-box; padding:0 20px;}
.campo{width: 100%;
    padding: 10px 0;
    text-indent: 5px;
    margin: 10px 0;
    border: 1px solid #CCC;
    border-left: 2px solid #AAA;}
.campo2{    width: 99.5%;
    border: 1px solid #ccc;
    border-left: 2px solid #aaa;
    text-indent: 4px;
	padding-top:10px;
    min-height: 100px;
	font-family: 'Lato', sans-serif;}
.btContato{background: #ff960c;
    border: 0;
    color: #292975;
    padding: 8px 15px;
    font-size: 12px;
    text-transform: uppercase;font-weight:bold;}
.btContato:hover{background: #292975;color:#ff960c;}
#erro{padding-left:20px;}
.desk{display:block;}
.mobi{display:none;}
.tab{display:block;}

#news button{    width: 15%;
    font-size: 17px;
    border: 0;
    padding: 3px 0;
    border-radius: 3px;
    color: #000;
    margin-left: 1%;
    cursor: pointer;
    background: #ccc;}
#news button:hover{text-indent:3px;}

#loader {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99999999999999999999999999;
	margin: 0;
	padding: 0;
	visibility: visible;
	opacity: 1;
	background-color: #FFF;}
#loader > div {
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: 100vw;
		height: 100%;
		max-height: 100vh;
		position: absolute;
		left: 0;
		top: 0;
		margin: 0;
		padding: 0;
		-moz-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-moz-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;		
	}
#sistemarastreamento p{margin-top: 40px;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
    color: #5b5b95;
    text-indent: 30px;}
.subContent{width: 135px;
    position: absolute;
    right: -10px;
    top: 38px;
    font-size: 11px;
    text-align: left;}
.subContent ul{    list-style: none;
    padding: 0;
    margin: 0;}
.subContent ul li{color: #FFF;
    text-transform: uppercase;
    border-top: 1px solid #777;
    border-bottom: 1px solid #333;
	background: #555;
	text-align:center;
}
.subContent ul li:hover{background:#ccc;}
.subContent a{border:none !important; font-size:11px !important; padding:0 !important;display:block !important;}
.telefones{position: fixed;
    left: 35px;
    top: 0;
    z-index: 999;
    width: 190px;
    text-align: center;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    padding: 10px;
    background: #f69527;
    color: #FFF;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;}
@media screen and (min-width: 1366px) {
.openServicos{margin-top:-640px;}
}
@media screen and (max-width: 930px) {
.telefones{position: absolute;}
.banInt{width:auto; min-height:150px;}
.openServicos {
    width: 100%;
    margin-top: 0px;
	margin-bottom:50px;
}
	.mapa{width:100%;}
	.desk{display:none;}
	.mobi{display:block;}
	.servInst{padding:10px;}
	.header .left{width:100%;}
	.header .right{width:100%; margin-top:2px;} 
	.banner{position:relative;}
	.banner img{    width: 106%;
    margin-left: -3%;}
	.header{width:100%;overflow:hidden;}
	.texto70{width:100%;}
	.downFolder1{padding:0;}
	.frotaRodape{display:none;}
	.rodape .menuRodape{width:30%;}
	.rodape .contatoRodape{width:30%;} 
	.downFolder1 .texto{padding-top:0;width:100%;text-align:center;float:none;}
.downFolder1 .botao{padding-top:0;width:100%;text-align:center;float:none;    height: 40px;
    margin-top: 20px;}
.servhome{padding:0;}
.textoContato{width:100%; margin:10px auto;}
.tab{display:block;}
}

@media screen and (max-width: 768px) {
.tab{display:none;}
.sobrenos p{text-align: center;}
.missao:hover{margin-top:0; width:100%;}
.missao{width:98%; margin:0 auto; float:none;}
.rodape .logo{width:100%;}
.rodape .menuRodape{width:50%;}
.rodape .contatoRodape{width:50%;font-size: 10px;} 
.blocoTexto{width:50%; margin-top:15px;}
.texto .title{    font-size: 13px;}
.sobreEsquerda{width:100%;padding-right:0;}
.sobreDireita{width:100%;}
.azul, .azul-s{font-size:21px;}
.servhome{width:100%; margin-top:50px;}
.servicoshome{width:50%;}
.textoServicos { width: 100%;margin:0 auto;}
}
@media screen and (max-width: 480px) {
	.texto{height: 140px;}
	#sistemarastreamento p{font-size: 13px;}
	.telefones{display:none;}
}